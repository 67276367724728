/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          //Reavel content when scrolling
          window.sr = new ScrollReveal({reset: true});

          var watch = {
              duration: 800,
              reset: false,
              origin: "top"
          };

          var d_logo = {
              duration: 800,
              reset: false,
              origin: "right",
              distance: "5rem",
              delay: 800
          };

          var place_picker = {
              duration: 800,
              reset: false,
              origin: "top",
              distance: "3rem",
              delay: 1200,
              easing: "ease-in-out"
          };

          sr.reveal('.watch', watch, 800);

          sr.reveal('.d-logo', d_logo, 800);

          sr.reveal('.place-picker', place_picker, 800);


          // Script to animate id target

          $('a[href^="#"]').on('click', function(event) {
              var target = $($(this).attr('href'));

              if( target.length ) {
                  event.preventDefault();
                  $('html, body').animate({
                      scrollTop: target.offset().top
                  }, 900);
              }
          });


          // form validation

          $.validator.addMethod("tel", function (value, element) {
              return this.optional(element) || /^([+]39)?\s?((313)|(32[03789])|(33[013456789])|(34[0256789])|(36[0368])|(37[037])|(38[0389])|(39[0123]))[\s-]?([\d]{7})$/.test(value);
          }, "Specificare un numero di telefono valido senza usare spazi.");

          $('#form1').validate({
              lang: 'it',
              rules: {
                  model: {
                      required: true
                  },
                  reference: {
                      required: true
                  },
                  year: {
                      required: true,
                      digits: true,
                      maxlength: 4,
                      minlength: 4
                  },

                  conditions: {
                      required: true
                  },
                  name: {
                      required: true
                  },

                  phone: {
                      required: true,
                      tel: true
                  }
              }
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


jQuery(document).ready(function($) {
    function maximizeIframes() {
        // Loop over every iframe on the page
        $('iframe').each(function() {
            var default_width = $(this).attr('width');
            var default_height = $(this).attr('height');
            $(this).css({
                width: default_width,
                height: default_height
            });

            var container_with = $(this).parent().width();
            // Get the iframe's intended aspect ratio via it's inline dimensions
            var ratio = $(this).attr('height') / $(this).attr('width');
            $(this).css('width', container_with);
            // Apply a CSS height that is in correct ratio to it's current width
            $(this).css('height', container_with * ratio);
        });
    }

    // Apply CSS height to every iframe in correct ratio to it's current width
    function resizeIframes() {
        // Loop over every iframe on the page
        $('iframe').each(function() {
            // Get the iframe's intended aspect ratio via it's inline dimensions
            var ratio = $(this).attr('height') / $(this).attr('width');
            // Apply a CSS height that is in correct ratio to it's current width
            $(this).css({
                'width': $(this).parent().width(),
                'height': $(this).width() * ratio
            });
        });
    }

    maximizeIframes();
    $(window).on('load', function() {
        maximizeIframes();
    });
    $(window).resize(function() {
        resizeIframes();
    });

    $('input[type="file"]').on('click', function(){
        $(this).val('');
    });

    $('input[type="file"]').change(function(){
        $(this).parent().find('.btn').addClass('loaded').text('Foto selezionata');
    });

    if ($('#form1').length) {
        $('main #form .guide p a').on('click', function() {
            $('#form1').submit();
        });
    }
});

// register jQuery extension
jQuery.extend(jQuery.expr[':'], {
    focusable: function (el, index, selector) {
        return $(el).is('a, button, :input, [tabindex]');
    }
});

$(document).on('keypress', 'input,select', function (e) {
    if (e.which == 13) {
        e.preventDefault();
        // Get all focusable elements on the page
        var $canfocus = $(':focusable');
        var index = $canfocus.index(document.activeElement) + 1;
        if (index >= $canfocus.length) index = 0;
        $canfocus.eq(index).focus();
    }
});
